<template>
  <el-dialog :visible.sync="visible" :title="form && form.id ? '编辑MRP产品配置' : '新增MRP产品配置'" append-to-body :close-on-click-modal="false" width="600px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="115px" label-suffix=":" v-if="form">
      <el-form-item prop="goodsId" label="产品名称">
      <div class="c h">        
        <sku-selector request-url="api/purchaseGoods" @submit="handleGoodsAdd" :multiple="false" buttonText="选择产品"/>
        <div v-if="form.goodsId" class="padding-0-10">{{productName}}</div>
      </div>
      </el-form-item>
      <el-form-item prop="purchaseDays" label="提前采购天数">
        <el-input-number v-model="form.purchaseDays" :min="0" :max="9999" :step="1" :precision="0" controls-position="right" style="width:100%" />
      </el-form-item>
      <el-form-item prop="safeStock" label="安全库存">
        <el-input-number v-model="form.safeStock" :min="0" :max="9999" :step="1" :precision="0" controls-position="right" style="width:100%" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { save } from "@/api/mrpproduct";
import skuSelector from "@/views/assembly/skuSelect";
export default {
  components: { skuSelector},
  data() {
    return {
      productName:"",
      visible: false,
      form: null,
      saving: false,
      rules: {
        goodsId: [{ required: true, message: "请选择产品" }],
      },
    };
  },

  methods: {
    handleGoodsAdd(rows) {
        if(rows){
        this.form.goodsId = rows.id;
        this.productName =  rows.name;
        }  
    },
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.saving = true;
          save(this.form)
            .then((res) => {
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500,
              });
              this.$parent.init();
              this.visible = false;
            })
            .finally((_) => {
              this.saving = false;
            });
        });
    },
    resetForm(form) {
      this.form = form || {
        goodsId: "",
        purchaseDays: 7,
        safeStock: 1,

      };
      this.visible = true;
    },
  },
};
</script>