<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="MRP产品配置">
    <div class="head-container">
      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
      </el-upload>
      <el-button type="primary" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
    </div>
    <div class="v" v-if="sucInfo">
      <span>导入结果：共{{sucInfo.errList.length+sucInfo.success}}条,成功{{sucInfo.success}}条,失败{{sucInfo.errList.length}}条</span>&nbsp;          
      <span class="padding-05">错误详情</span>
      <el-table :data="errList" border style="width: 100%;">
        <el-table-column prop="erpCode"  min-width="200" show-overflow-tooltip  label="产品编码" />
        <el-table-column prop="name"  min-width="200" show-overflow-tooltip  label="产品名称" />
        <el-table-column prop="purchaseDays"  min-width="200" show-overflow-tooltip  label="提前采购天数" />
        <el-table-column prop="safeStock"  min-width="200" show-overflow-tooltip  label="安全库存" />
        <el-table-column prop="error" min-width="200" show-overflow-tooltip label="错误信息"/>
      </el-table>
    </div>
  </el-dialog>
</template>
<script>
import config from "@/config";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  data() {
    return {
      uploading: false,
      uploadUrl: config.hosts.request + "api/import",
      uploadHeaders: {},
      downloadLoading: false,
      dialog: false,
      errList:[],
      sucInfo:null,
      errInfo:null,
    }
  },
  computed:{
    btnName(){ 
      return this.uploading?"导入中":"选择文件";
    }
  },
  methods:{
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(res) {      
      this.sucInfo = res;
      this.errList = res.errList;
      this.uploading = false;
      if(res.success && res.success>0){      
        this.$parent.init();      
      }
    },
    handleUploadError(err) {
      this.errInfo = err;
      this.uploading = false;
    },    
    downloadTemplate(){
      this.downloadLoading = true;
      download("api/template")
        .then(result => {
          downloadFile(result, "MRP产品配置模板", "xlsx");
          this.downloadLoading = false;
        })
        .catch(err => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    show(){
      this.uploadHeaders.Authorization = "Bearer " + getToken();
      this.sucInfo = null;
      this.dialog = true;
    }
  }
}
</script>