
import request from '@/utils/request'

export function save(data) {
    return request({
        url: 'api/mrpGoodsConfig',
        method: 'post',
        data
    })
}

export function del(id) {
    return request({
        url: 'api/mrpGoodsConfig/' + id,
        method: 'delete'
    })
}

